import React from 'react'
import StatsItem from '../StatsBlock/StatsItem'

const Timeline = () => {
  const events = [
    {
      year: '2015',
      event: <span>
        <strong>OCTOBER</strong>: Building on years working together across Africa, Michael and Justin form Field to transform the role of technology, business, and supply chain in sustainable, equitable development.
      </span>
    },
    {
      year: '2016',
      event: <span>
        <strong>JANUARY</strong>: Begins development of <em>Field Supply</em>, an offline-first SaaS solution simplifying the process of digitizing and optimizing large-scale supply chains, and <em>Forth</em>, a fourth-party logistics service leveraging Field Supply with the mission to upskill Africa’s underutilized informal transport labor to extend last-mile delivery at scale.
        <br /><br />
        <strong>AUGUST</strong>: Maiden deployment of <em>Field Supply</em>, as the Supply Chain Control Tower for Nigeria’s national immunization program, integrating a legacy commercial ERP and retiring parallel spreadsheet reporting into an offline-first, web-based dashboard for supply chain managers at each of the country’s 820 immunization warehouses.
        <br /><br />
        Signs an exclusive collaboration to become the official platform for the largest association of information transport workers in Africa, Nigeria’s National Union of Road Transport Workers (NURTW).
        <br /><br />
        <strong>NOVEMBER</strong>: Responding to the worst Nigerian recession in a generation, launches a field-based research initiative to identify opportunities to help independent pharmacies stabilize affordable supply of pharmaceuticals without sacrificing quality.
      </span>
    },
    {
      year: '2017',
      event: <span>
        <strong>MARCH</strong>: Begins work with the International Federation of the Red Cross & Red Crescent (IFRC) to digitize supply chain operations and visualize performance for HIV, TB, Malaria, and immunization the Central African Republic.
        <br /><br />
        <strong>MAY</strong>: With support from UNICEF, WHO, and the Bill & Melinda Gates Foundation, begins development of major new Field Supply features, including planning, batch-tracking, offline-first shipments, integrations with major health information systems, and performance management analytics - landmark advances in making next-generation digital tools accessible to users end to end.
        <br /><br />
        <strong>JUNE</strong>: Commercial launch of Shelf Life in Abuja, Nigeria - pioneering vendor-managed inventory as a service and Pay-As-You-Sell subscriptions for retail pharmacies.
        <br /><br />
        <strong>AUGUST</strong>: Responding to an invitation from the Rockefeller Foundation’s Yieldwise initiative, begins research in Kano and Lagos, Nigeria, resulting in <em>Acre</em>, a Forth-enabled service concept helping smallholders access markets and reduce postharvest loss in positive-sum, sustainable transformation of Nigeria’s tomato value chain.
        <br /><br />
        <strong>OCTOBER</strong>: Nigeria’s National Supply Chain Integration Project (NSCIP) adopts Field Supply as the country’s health logistics management information system, beginning a sprint to add HIV, Malaria, and Family Planning programs to the platform - entrusting Field Supply with management of planning, reporting, and analytics on hundreds of new SKUs serving twenty-nine unique services across tens of thousands of new clinics, hospitals, and labs across the country.
      </span>
    },
    {
      year: '2018',
      event: <span>
        <strong>JANUARY</strong>: With two years of learning and iterating, amid a step change in mandate for <em>Field Supply</em> and phenomenal demand for <em>Shelf Life</em>, Field decides to focus entirely on healthcare, suspending work on <em>Acre</em>, and consolidating <em>Forth</em> into <em>Shelf Life</em> to bring the absolute focus needed to deliver these services at scale.
        <br /><br />
        <strong>FEBRUARY</strong>: New <em>Field Supply</em> immunization program features go live. Government, UNICEF, and lead national rollout, alongside government and UNICEF - digitizing and strengthening Africa’s largest immunization supply chain.
        <br /><br />
        <strong>MARCH</strong>: Berlin, Germany office officially opens.
        <br /><br />
        <strong>APRIL</strong>: Nigeria’s HIV, Malaria, and Family Planning programs go live on Field Supply with the first phase of features. Nationwide onboarding of State Logistics Management Coordination Units and Federal Government staff begins.
        <br /><br />
        <strong>AUGUST</strong>: Shelf Life launches in Nairobi, Kenya.
        <br /><br />
        <strong>OCTOBER</strong>: Shelf Life raises money from the Bill & Melinda Gates Foundation to test its business and impact cases in new markets: Lagos, Nigeria and Nairobi, Kenya. Shelf Life ends 2018 having grown over 300% in revenue while advancing over 80,000 units of medicine over 1,000 deliveries at better than 95% on-shelf availability.
        <br /><br />
        <strong>NOVEMBER</strong>: Embarks on an aggressive development plan to add Nigeria’s national Tuberculosis program, as well as new inventory risk management, ordering and warehouse integration features to Field Supply throughout 2019.
      </span>
    }
  ]
  return (
    <div className='timeline' style={{ padding: '24px 0' }}>
      {events.map(event => (
        <div key={event.year} style={{ padding: '24px' }}>
          <StatsItem keyNumber={event.year} legend={event.event} onColor='grey' />
        </div>
      ))}
    </div>
  )
}

export default Timeline
